<template>
  <div class="form-layout form-layout-2">
    <div class="row no-gutters">
      <table class="table table-striped mg-b-0">
        <thead>
          <tr>
            <th class="tx-center wd-100">Container</th>
            <th class="tx-center">Chegada</th>
            <th class="tx-center">Free</th>
            <th class="tx-center">Deadline</th>
            <th class="tx-center">Carregamento</th>
            <th class="tx-center">Devolução</th>
            <th class="tx-center wd-150">Demurrage (R$)</th>
            <th class="tx-center wd-150">Reparo (R$)</th>
            <th class="tx-center">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr-inline
            v-for="item in data"
            :key="item.id"
            :row="item"
            @save="update"
            customClassesActions="container-actions"
          >
            <td-inline v-model="item.container" :width="200" />
            <td-inline v-model="item.arrival" type="date" icon="fa-calendar" />
            <td-inline
              v-model="item.freetime"
              @input="calculateDeadlineAfterSetFreetimeExisting(item)"
              class="tx-medium tx-gray-900"
            />
            <td-inline v-model="item.deadline" type="date" icon="fa-calendar" />
            <td-inline
              placeholder="Data"
              v-model="item.loadingDate"
              type="date"
              icon="fa-calendar"
            />
            <td-inline
              v-model="item.devolution"
              type="date"
              icon="fa-calendar"
            />
            <td-inline v-model="item.demurrage" type="money" />
            <td-inline v-model="item.repair" type="money" />
            <template slot="actions">
              <a
                href="#"
                @click.prevent="remove(item)"
                class="btn btn-danger btn-icon rounded-circle"
              >
                <div><i class="icon ion-trash-a"></i></div>
              </a>
            </template>
          </tr-inline>
          <tr-inline
            class="new-item"
            :isNew="true"
            :row="newData"
            @save="create"
            customClassesActions="container-actions"
          >
            <td-inline placeholder="Container" v-model="newData.container" />
            <td-inline
              placeholder="Chegada"
              v-model="newData.arrival"
              type="date"
              icon="fa-calendar"
            />
            <td-inline
              placeholder="Freetime"
              v-model="newData.freetime"
              @input="calculateDeadlineAfterSetFreetime()"
              type="number"
            />
            <td-inline
              placeholder="Deadline"
              v-model="newData.deadline"
              type="date"
              icon="fa-calendar"
            />
            <td-inline
              placeholder="Carregamento"
              v-model="newData.loadingDate"
              type="date"
              icon="fa-calendar"
            />
            <td-inline
              placeholder="Devolução"
              v-model="newData.devolution"
              type="date"
              icon="fa-calendar"
            />
            <td-inline
              placeholder="Demurrage"
              v-model="newData.demurrage"
              type="money"
            />
            <td-inline
              placeholder="Reparo"
              v-model="newData.repair"
              type="money"
            />
          </tr-inline>
        </tbody>
        <tfoot>
          <tr>
            <th colspan="6" class="tx-right">Valores Totais</th>
            <th class="tx-right">
              R$ {{ totalDemurrage | numeralFormat("0,0.00") }}
            </th>
            <th class="tx-right">
              R$ {{ totalRepair | numeralFormat("0,0.00") }}
            </th>
            <th class="wd-150"></th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import moment from "moment";
import config from "@/config.js";
import { mapState } from "vuex";
import inlineRow from "@/components/InlineRow.vue";
import InlineCell from "@/components/InlineCell.vue";

import Swal from "sweetalert2";

export default {
  name: "FormContainers",
  props: [],
  components: {
    "tr-inline": inlineRow,
    "td-inline": InlineCell,
  },
  data() {
    return {
      model: {},
      data: [],
      newData: {
        container: "",
        arrival: "",
        freetime: "",
        deadline: "",
        devolution: "",
        loadingDate: "",
        demurrage: 0,
        repair: 0,
        importProcess: null,
      },
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    freetimeIsFilled() {
      if (!this.process.freetime) {
        Swal.fire("Logística", "Freetime não informado no processo!", "error");
        return false;
      }
      return true;
    },
    calculateDeadlineAfterSetFreetime() {
      if( this.newData.arrival) {
        const dataChegada = moment(this.newData.arrival, "YYYY-MM-DD[T]hh:mm:ss");

        let dataDeadline = dataChegada;

        if (this.newData.freetime && this.newData.freetime != "undefined") {
          dataDeadline = dataChegada
            .add(parseInt(this.newData.freetime - 1), "days")
            .format("YYYY-MM-DD[T]hh:mm:ss");
        }

        this.newData.deadline = dataDeadline;
      }
    },
    calculateDeadlineAfterSetFreetimeExisting(item) {
      const dataChegada = moment(item.arrival, "YYYY-MM-DD[T]hh:mm:ss");

      let dataDeadline = dataChegada;

      if (item.freetime && item.freetime != "undefined") {
        dataDeadline = dataChegada
          .add(parseInt(item.freetime - 1), "days")
          .format("YYYY-MM-DD[T]hh:mm:ss");
      }

      item.deadline = dataDeadline;
    },
    updateDeadline(item, target) {
      if (item.arrival && item.freetime) {
        item.deadline = moment(item.arrival).add(
          parseInt(item.freetime) - 1,
          "day"
        );
      }
    },

    async update(value) {
      this.submit(value, "put");
    },
    async create(value) {
      if (this.freetimeIsFilled()) {
        this.submit(value, "post");
      }
    },
    async submit(value, method) {

      const requiredFields = [
        { field: 'container', message: 'Container não foi preenchido.' },
        { field: 'arrival', message: 'Data de Chegada não foi preenchido.' },
        { field: 'freetime', message: 'Freetime não foi preenchido.' },
        { field: 'deadline', message: 'Data de Deadline não foi preenchido.' },
      ];

      const messageErroInputs = requiredFields.find((field) => !value[field.field])?.message;

      if (messageErroInputs) {
        Swal.fire(
          "Logística - Containers",
          messageErroInputs,
          "error"
        );
        return;
      }

      this.$store.commit("setLoading", {
        show: true,
        label: `Processo ${this.process.id}`,
        description: "Salvando dados, aguarde...",
      });

      // converte para formato correto de data
      const formatDate = (date) => date ? moment(date).format("YYYY-MM-DD[T]hh:mm:ss") : "";

      value.arrival = formatDate(value.arrival);
      value.deadline = formatDate(value.deadline);
      value.devolution = formatDate(value.devolution);
      value.loadingDate = formatDate(value.loadingDate);

      this.newData.importProcess = `${config.env.API_DEFAULT.host}/importProcesses/${this.process.id}`;

      try {
        let response;
        
        if (method == "put") {
          response = await Axios.put(value._links.self.href, value);
          let item = this.data.find(
            (item) => item._links.self.href === value._links.self.href
          );
          item = response.data;
        } else {
          response = await Axios.post(
            `${config.env.API_DEFAULT.host}/process/import/${this.process.id}/containers/save`,
            value
          );
          this.data.push(response.data.data);
        }

        this.newData = {
          container: "",
          arrival: this.process.datesArrivalDate ?this.$moment(this.process.datesArrivalDate).format("Y-MM-DDTLTS") : "",
          freetime: this.process.freetime,
          deadline: this.newData.arrival ? moment(this.newData.arrival).add(parseInt(this.newData.freetime) - 1,"day") : "",
          devolution: null,
          loadingDate: null,
          demurrage: 0,
          repair: 0,
          importProcess: null,
        };

        this.$store.commit("setLoading", {
          show: false,
        });
      } catch (err) {
        this.$store.commit("setLoading", { show: false });
        let message = err?.response?.data?.message || err

        Swal.fire(
          "Logística - Containers",
          message,
          "error"
        );
      }
    },
    async remove(value) {
      try {
        this.$store.commit("setLoading", {
          show: true,
          label: `Processo ${this.process.id}`,
          description: "Processando requisição, aguarde...",
        });
        const response = await Axios.delete(value._links.self.href, value);
        const index = this.data.findIndex(
          (item) => item._links.self.href === value._links.self.href
        );
        this.data.splice(index, 1);
        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
      } catch (err) {
        this.$store.commit("setLoading", { show: false });
        Swal.fire("Logística", err, "error");
      }
    },

    async loadData() {
      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/importProcesses/${this.process.id}/containers/`
        );
        this.data = response.data._embedded.processContainers;
        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
      } catch (err) {
        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
        Swal.fire("Ops", err, "error");
      }
    },
  },
  computed: {
    ...mapState({
      process: (state) => state.ProcessStore.activeProcess,
    }),

    totalDemurrage() {
      return this.data.reduce(
        (value, item) => value + parseFloat(item.demurrage),
        0
      );
    },
    totalRepair() {
      return this.data.reduce(
        (value, item) => parseFloat(value) + parseFloat(item.repair),
        0
      );
    },
  },
  mounted() {
    this.model = {
      ...this.process,
    };

    if (
      !this.process.datesArrivalDate &&
      (this.process.step.id === 3 ||
        this.process.step.id === 4 ||
        this.process.step.id === 5)
    ) {
      Swal.fire(
        "Logística",
        "Data de Chegada não informada no processo!",
        "error"
      );
    }

    this.freetimeIsFilled();

    this.loadData();

    if (this.process.datesArrivalDate) {
      this.newData.arrival = this.$moment(this.process.datesArrivalDate).format(
        "Y-MM-DDTLTS"
      );
    }

    this.newData.freetime = this.process.freetime;

    if (this.newData.arrival) {  
      this.newData.deadline = moment(this.newData.arrival).add(
        parseInt(this.newData.freetime) - 1,
        "day"
      );
    }
  },
  watch: {
    process() {
      this.model = {
        ...this.process,
      };
      this.loadData();
    },
  },
};
</script>

<style>
</style>
