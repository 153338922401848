<template>
  <form id="sidebarDates" class="form-layout form-layout-2 form-dates-sidebar">
    
    <div class="row no-gutters">
      <div class="col-md-12">

        <label class="section-title mg-t-0"> <i class="fa fa-clock"></i> Transit Time </label>
        <div class="card card-sales pd-y-10">
          <div class="row mg-b-0-force pd-0">
            <div class="col">
              <label class="tx-10"> CONTRATADO </label>
              <p>{{ transitTimeContratado ? transitTimeContratado + ' dias' : ' - ' }}</p>
            </div>
            <div class="col">
              <label class="tx-10"> ATUAL </label>
              <p>{{ transitTimeAtual }} dias</p>
            </div>
          </div>
        </div>
        
        <hr>

        <label class="section-title mg-t-20"> <i class="fa fa-calendar-times-o"></i> Datas Previstas</label>
        <div class="row no-gutters">
          <div class="form-group col-md-12 mg-md-t--1 col-md-6 bd-t-1-force bd-t-1">
            <label class="form-control-label"> Prontidão </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker v-model="datesEstimatedGoodsReadinesDate" name="datesEstimatedGoodsReadinesDate"
                             inputName="datesEstimatedGoodsReadinesDate" class="form-control" format="DD/MM/YY"
                             lang="pt-br" placeholder="Selecione"
                             @change="checkDatesEstimatedGoodsReadinesDate"
                             ></date-picker>
              </div>
            </div>
          </div>
          <div class="form-group col-md-6 mg-md-t--1" :class="initialDatesETD != null ? 'bg-gray-200' : ''">
            <label class="form-control-label"> ETD Inicial </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker v-model="initialDatesETD" @change="changeInitialDatesETD" name="initialDatesETD"
                             inputName="initialDatesETD" class="form-control" format="DD/MM/YY" lang="pt-br"
                             placeholder="Selecione"></date-picker>
              </div>
            </div>
          </div>
          <div class="form-group col-md-6 mg-md-t--1 bd-l-0-force">
            <label class="form-control-label"> ETD </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker v-model="datesETD" name="datesETD" inputName="datesETD" class="form-control"
                             format="DD/MM/YY" lang="pt-br" placeholder="Selecione"></date-picker>
              </div>
            </div>
          </div>
        </div>

        <div class="row no-gutters">
          <div class="form-group col-md-6 mg-md-t--1  bd-t-0-force bd-b-0-force">
            <label class="form-control-label"> ETA </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker v-model="datesETA" name="datesETA" inputName="datesETA" class="form-control"
                             format="DD/MM/YY" lang="pt-br" placeholder="Selecione"></date-picker>
              </div>
            </div>
          </div>

          <div class="form-group col-md-6 mg-md-t--1 bd-l-0-force bd-t-0-force bd-b-0-force">
            <label class="form-control-label"> Entrega Cliente </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker v-model="datesEstimatedCustomerDeliverDate" name="datesEstimatedCustomerDeliverDate"
                             inputName="datesEstimatedCustomerDeliverDate" class="form-control" format="DD/MM/YY"
                             lang="pt-br" placeholder="Selecione"></date-picker>
              </div>
            </div>
          </div>
        
          <div class="form-group col-md-6 mg-md-t--1">
            <label class="form-control-label">Numerário</label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker
                  lang="pt-br"
                  v-model="estimatedStepCashRequest"
                  v-validate="'required'"
                  class="form-control"
                  format="DD/MM/YY"
                  inputName="estimatedStepCashRequest"
                  name="estimatedStepCashRequest"
                  placeholder="Selecione"
                ></date-picker>
              </div>
            </div>
            <span v-if="!datesETA" class="badge badge-danger tx-normal">
              Preencher quando 
              <br />
              não houver o ETA
            </span>
        
          </div>

          <div class="form-group col-md-6 mg-md-t--1 bd-l-0-force bg-gray-100">
            <label class="form-control-label">Previsão Invoice</label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker
                  lang="pt-br"
                  v-model="estimatedInvoiceDate"
                  v-validate="'required'"
                  class="form-control"
                  format="DD/MM/YY"
                  inputName="estimatedInvoiceDate"
                  name="estimatedInvoiceDate"
                  placeholder="Selecione"
                  disabled
                ></date-picker>
              </div>
            </div>       
          </div>

        </div>

        <hr>

        <label class="section-title mg-t-20"> <i class="fa fa-calendar-check-o"></i> Datas Confirmadas</label>

        <div class="row no-gutters">
          <div class="form-group col-md-6 mg-md-t--1 bd-r-0-force">
            <label class="form-control-label"> Prontidão </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker v-model="datesGoodsReadinesDate" name="datesGoodsReadinesDate"
                             inputName="datesGoodsReadinesDate" class="form-control" format="DD/MM/YY" lang="pt-br"
                             placeholder="Selecione"></date-picker>
              </div>
            </div>
          </div>

          <div class="form-group col-md-6  mg-md-t--1 ">
            <label class="form-control-label"> Embarque </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker
                  v-model="datesDepartureDate"
                  name="datesDepartureDate"
                  inputName="datesDepartureDate"
                  class="form-control" format="DD/MM/YY" lang="pt-br" v-validate="'required'"
                  placeholder="Selecione"
                ></date-picker>
              </div>
            </div>
          </div>

          <div class="form-group col-md-6 mg-md-t--1 bd-r-0-force bd-t-0-force">
            <label class="form-control-label"> Atracação </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker v-model="mooringDate" name="mooringDate" inputName="mooringDate" class="form-control"
                             format="DD/MM/YY" lang="pt-br" placeholder="Selecione"></date-picker>
              </div>
            </div>
          </div>

          <div class="form-group col-md-6 mg-md-t--1 bd-t-0-force">
            <label class="form-control-label"> Chegada </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker v-model="datesArrivalDate" name="datesArrivalDate" inputName="datesArrivalDate"
                             class="form-control" format="DD/MM/YY" lang="pt-br" v-validate="'required'"
                             placeholder="Selecione"></date-picker>
              </div>
            </div>
          </div>

          <div class="form-group col-md-6 mg-md-t--1 bd-r-0-force bd-t-0-force">
            <label class="form-control-label"> Presença </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker v-model="datesGoodsPresenceDate" name="datesGoodsPresenceDate"
                             inputName="datesGoodsPresenceDate" class="form-control" format="DD/MM/YY" lang="pt-br"
                             v-validate="'required'" placeholder="Selecione"></date-picker>
              </div>
            </div>
          </div>

          <div class="form-group col-md-6 mg-md-t--1 bd-t-0-force">
            <label class="form-control-label"> Vistoria da DI </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker v-model="datesInspectionOfTheDIDate" name="datesInspectionOfTheDIDate"
                             inputName="datesInspectionOfTheDIDate" class="form-control" format="DD/MM/YY" lang="pt-br"
                             v-validate="'required'" placeholder="Selecione"></date-picker>
              </div>
            </div>
          </div>

          <div class="form-group col-md-6 mg-md-t--1 bd-r-0-force">
            <label class="form-control-label"> Carregamento </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker v-model="datesGoodsLoadingDate" name="datesGoodsLoadingDate"
                             inputName="datesGoodsLoadingDate" class="form-control" format="DD/MM/YY" lang="pt-br"
                             v-validate="'required'" placeholder="Selecione"></date-picker>
              </div>
            </div>
          </div>

          <div class="form-group col-md-6 mg-md-t--1 ">
            <label class="form-control-label"> Entrega Cliente </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker v-model="datesCustomerDeliverDate" name="datesCustomerDeliverDate"
                             inputName="datesCustomerDeliverDate" class="form-control" format="DD/MM/YY" lang="pt-br"
                             v-validate="'required'" placeholder="Selecione"></date-picker>
              </div>
            </div>
          </div>
        </div>

        <hr>
        <label class="section-title mg-t-20"><i class="fa fa-tree"></i> MAPA</label>

        <div class="row no-gutters">
          <div class="form-group col-md-6 mg-md-t--1 col-md-6 ">
            <label class="form-control-label"> Vistoria MAPA </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker v-model="datesInspectionMAPADate" name="datesInspectionMAPADate"
                             inputName="datesInspectionMAPADate" class="form-control" format="DD/MM/YY" lang="pt-br"
                             v-validate="'required'" placeholder="Selecione"></date-picker>
              </div>
              <div class="input-group-solved-after"><label class="ckbox wd-16 mg-b-0"> <input
                v-model="datesInspectionMAPADateSolved" type="checkbox"
                name="datesInspectionMAPADateSolved"><span></span> </label></div>
            </div>
          </div>
          <div class="form-group col-md-6 mg-md-t--1 bd-l-0-force">
            <label class="form-control-label"> Liberação MAPA </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker v-model="datesReleaseMAPADate" name="datesReleaseMAPADate" inputName="datesReleaseMAPADate"
                             class="form-control" format="DD/MM/YY" lang="pt-br" v-validate="'required'"
                             placeholder="Selecione"></date-picker>
              </div>
              <div class="input-group-solved-after"><label class="ckbox wd-16 mg-b-0"> <input
                v-model="datesReleaseMAPADateSolved" type="checkbox" name="datesReleaseMAPADateSolved"><span></span>
              </label></div>
            </div>
          </div>

          <div class="form-group col-md-12 mg-md-t--1 col-md-6 bd-t-0-force">
            <label class="form-control-label"> Vínculo no Recinto </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker v-model="datesLinkInTheEnclosureDate" name="datesLinkInTheEnclosureDate"
                             inputName="datesLinkInTheEnclosureDate" class="form-control" format="DD/MM/YY" lang="pt-br"
                             v-validate="'required'" placeholder="Selecione"></date-picker>
              </div>
              <div class="input-group-solved-after"><label class="ckbox wd-16 mg-b-0"> <input
                v-model="datesLinkInTheEnclosureDateSolved" type="checkbox"
                name="datesLinkInTheEnclosureDateSolved"><span></span> </label></div>
            </div>
          </div>
        </div>

        <hr>
        <label class="section-title mg-t-20"> <i class="fa fa-newspaper-o"></i> Conhecimento</label>

        <div class="row no-gutters">

          <div class="form-group col-md-6">
            <label class="form-control-label"> Siscarga</label>
            <div class="input-group-solved">
              <div class="input-group-solved-before mg-r-5 mg-t-3">
                <toggle-button v-model="datesSiscargaState" name="datesSiscargaState" :sync="true"
                               :labels="{checked: 'LIBER', unchecked: ' PEND'}" :color="{unchecked: '#bf0909'}"
                               :height="16" :width="50"/>
              </div>
              <div class="input-group-solved-after">
                <date-picker v-model="datesSiscargaDate" name="datesSiscargaDate" inputName="datesSiscargaDate"
                             class="form-control" format="DD/MM/YY" lang="pt-br" v-validate="'required'"
                             placeholder="Selecione"></date-picker>
              </div>
            </div>
          </div>

          <div class="form-group col-md-6 bd-l-0-force">
            <label class="form-control-label"> Liberação AWB/BL </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before mg-t-3">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker v-model="datesBillOfLadingReleaseDate" name="datesBillOfLadingReleaseDate"
                             inputName="datesBillOfLadingReleaseDate" class="form-control" format="DD/MM/YY"
                             lang="pt-br" v-validate="'required'" placeholder="Selecione"></date-picker>
              </div>
            </div>
          </div>

          <div class="form-group col-md-12 mg-md-t--1 col-md-6 bd-t-0-force">
            <label class="form-control-label">Termo de falta de Avaria</label>
            <div class="input-group-solved">
              <div class="input-group-solved-before mg-r-5 mg-t-3">
                <toggle-button v-model="datesTFASolved" name="datesTFASolved" :sync="true"
                               :labels="{checked: ' LIBERADO ', unchecked: ' PENDENTE '}"
                               :color="{unchecked: '#bf0909'}" :height="16" :width="80"/>
              </div>
              <div class="input-group-solved-after"></div>
            </div>
          </div>
        </div>

        <hr>

        <label class="section-title mg-t-20"> <i class="fa fa-truck"></i> Transferência de Carga</label>

        <div class="row no-gutters">
          <div class="form-group col-md-6">
            <label class="form-control-label"> DTC / DTA </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before mg-r-5 mg-t-1">
                <toggle-button v-model="datesIsThereDTCDTATransfer" name="datesIsThereDTCDTATransfer" :sync="true"
                               :labels="{checked: 'SIM', unchecked: 'NÃO'}" :color="{unchecked: '#bf0909'}" :height="16"
                               :width="50"/>
              </div>
              <div class="input-group-solved-after">
                <date-picker v-model="datesDTCDTA" name="datesDTCDTA" inputName="datesDTCDTA" class="form-control"
                             format="DD/MM/YY" lang="pt-br" v-validate="'required'"
                             placeholder="Selecione"></date-picker>
              </div>
            </div>
          </div>

          <div class="form-group col-md-6 bd-l-0-force">
            <label class="form-control-label"> Desova </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before mg-r-5 mg-t-1">
                <toggle-button v-model="datesThereIsDesova" name="datesThereIsDesova" :sync="true"
                               :labels="{checked: 'SIM', unchecked: 'NÃO'}" :color="{unchecked: '#bf0909'}" :height="16"
                               :width="50"/>
              </div>
              <div class="input-group-solved-after">
                <date-picker v-model="datesDesova" name="datesDesova" inputName="datesDesova" class="form-control"
                             format="DD/MM/YY" lang="pt-br" v-validate="'required'"
                             placeholder="Selecione"></date-picker>
              </div>
            </div>
          </div>

          <div class="form-group col-md-6 bd-t-0-force">
            <label class="form-control-label"> Fat Recinto </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before ">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker v-model="datesEnclosureBillingDate" name="datesEnclosureBillingDate"
                             inputName="datesEnclosureBillingDate" class="form-control" format="DD/MM/YY" lang="pt-br"
                             v-validate="'required'" placeholder="Selecione"></date-picker>
              </div>
            </div>
          </div>

          <div class="form-group col-md-6 bd-t-0-force bd-l-0-force">
            <label class="form-control-label"> Comex </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker v-model="datesComexDate" name="datesComexDate" inputName="datesComexDate"
                             class="form-control" format="DD/MM/YY" lang="pt-br" v-validate="'required'"
                             placeholder="Selecione"></date-picker>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row no-gutters">
        <div class="col-md-12">
          <hr>
          <div v-if="legacyId !== null">
            <label class="section-title mg-t-20"><i class="fa fa-calendar-times-o"></i> Abertura </label>
            {{ legacyCreated | moment("DD/MM/YYYY \\à\\s HH:mm:ss") }} por {{ legacyCreatedBy }}
          </div>
          <div v-else>
            <label class="section-title mg-t-20">
              <i class="fa fa-calendar-times-o"></i>
              Abertura
            </label>
            {{ created | moment("DD/MM/YYYY \\à\\s HH:mm:ss") }} por {{ createdBy }}
          </div>
          <hr>
        </div>
      </div>
    </div>

    <v-modal :handler="modalDatesEstimatedGoodsReadinesDate" title="Informações para o Numerário Previsto" description="" :width="600" @requestClose="closeModalDatesEstimatedGoodsReadinesDate">
      <template #content>
        <div>
          <form @submit.prevent="updateEstimatedStepCashRequest" class="columns-12 md-gap">
            <div class="span-12">
              <p class="text-md">Com base na Data de Prontidão, quantos dias serão adicionados ao Numerário Previsto?</p>
              <select-wrapper label="Selecione uma opção" v-model="modalData.value" :options="modalData.options"/>
            </div>

            <div class="span-12 text-center">
              <button type="submit" class="crm-button">Salvar</button>
            </div>
          </form>
        </div>
      </template>
    </v-modal>
  </form>
</template>

<script>
import Axios from "axios";
import config from "@/config.js";
import { mapState, mapActions } from "vuex";
import DatePicker from "vue2-datepicker";
import ToggleButton from "vue-js-toggle-button/src/Button.vue";
import Swal from "sweetalert2";
import moment from "moment";
import VModal from "@/components/VModal.vue";
import SelectWrapper from "@/components/SelectWrapper.vue";

export default {
  components: {
    DatePicker,
    ToggleButton,
    VModal,
    SelectWrapper,
  },
  name: "FormSidebarDates",
  data() {
    return {
      modalDatesEstimatedGoodsReadinesDate: false,
      modalData: {
        value: null,
        options: [
          {
            label: "Aéreo -  Europa / USA + 7 dias",
            value: 7,
          },
          {
            label: "Aéreo - Asia + 15 dias",
            value: 15,
          },
          {
            label: "Marítimo Europa / USA + 30 dias",
            value: 30,
          },
          {
            label: "Marítimo - Asia  + 60 dias",
            value: 60,
          },
        ],
      },
      initialDatesETD: null,
      datesEstimatedGoodsReadinesDate: null,
      datesETD: null,
      datesETA: null,
      datesEstimatedCustomerDeliverDate: null,
      datesGoodsReadinesDate: null,
      datesDepartureDate: null,
      mooringDate: null,
      datesArrivalDate: null,
      datesGoodsPresenceDate: null,
      datesInspectionOfTheDIDate: null,
      datesGoodsLoadingDate: null,
      datesCustomerDeliverDate: null,
      datesInspectionMAPADate: null,
      datesInspectionMAPADateSolved: null,
      datesReleaseMAPADate: null,
      datesReleaseMAPADateSolved: null,
      datesLinkInTheEnclosureDate: null,
      datesLinkInTheEnclosureDateSolved: null,
      datesSiscargaState: null,
      datesSiscargaDate: null,
      datesBillOfLadingReleaseDate: null,
      datesTFASolved: null,
      datesIsThereDTCDTATransfer: null,
      datesDTCDTA: null,
      datesThereIsDesova: null,
      datesDesova: null,
      datesEnclosureBillingDate: null,
      datesComexDate: null,
      legacyId: null,
      legacyCreated: null,
      legacyCreatedBy: null,
      created: null,
      createdBy: null,
      estimatedStepCashRequest: null,
      estimatedInvoiceDate: null,
      transitTimeContratado: '',
      transitTimeAtual: ' - ',
    };
  },
  methods: {
    ...mapActions("ProcessStore", ["getFreightQuotationsResponses"]),
    async calculateTransitTime() {
      this.transitTimeContratado = "";
      
      if (this.datesDepartureDate !== null && this.datesArrivalDate !== null) {
        this.transitTimeAtual = moment(this.datesArrivalDate).diff(moment(this.datesDepartureDate), 'days');
      } else if(this.datesDepartureDate !== null && this.datesETA !== null) {
        this.transitTimeAtual = moment(this.datesETA).diff(moment(this.datesDepartureDate), 'days');
      } else if(this.datesETD !== null && this.datesETA !== null) {
        this.transitTimeAtual = moment(this.datesETA).diff(moment(this.datesETD), 'days');
      } else {
        this.transitTimeAtual = ' - ';
      }

      if (this.freightQuotationsResponses.freightQuotations && this.freightQuotationsResponses.freightQuotations.length > 0) {
        const quotation = this.freightQuotationsResponses.freightQuotations.find(item => item.approved === true);

        if (quotation) {
          this.transitTimeContratado = quotation.transitTime;
        }
      }

    },
    checkDatesEstimatedGoodsReadinesDate(){
      if (this.datesEstimatedGoodsReadinesDate != null && !this.datesETA) {
        this.modalDatesEstimatedGoodsReadinesDate = true;
      } else {
        this.modalDatesEstimatedGoodsReadinesDate = false;
      }

      return;
    },
    updateEstimatedStepCashRequest() {
      this.$store.commit("setLoading", {
        show: true,
        label: `Numerário Previsto`,
        description: "Atualizando...",
      });
      try {
        this.estimatedStepCashRequest = moment(this.datesEstimatedGoodsReadinesDate).add("days", this.modalData.value).startOf("day").toDate();
        this.diffObjects("estimatedStepCashRequest");
      } catch (error) {
        console.error(error);

        Swal.fire({
          title: "Erro ao atualizar numerário previsto",
          text: error.response.data.message,
          type: "error",
        });
      } finally {
        this.$store.commit("setLoading", {
          show: false,
        });

        this.closeModalDatesEstimatedGoodsReadinesDate();
      }
    },
    closeModalDatesEstimatedGoodsReadinesDate() {
      this.modalDatesEstimatedGoodsReadinesDate = false;
      this.modalData.value = null;

      return;
    },
    changeInitialDatesETD() {
      if (this.initialDatesETD != null) {
        Swal.fire({
          title: "Você tem certeza que este dado esta correto?",
          html: `Deseja realmente alterar a data inicial de ETD para ${moment(
            this.initialDatesETD
          ).format("DD/MM/YYYY")}`,
          type: "warning",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Salvar",
          denyButtonText: `Cancelar`,
        }).then((result) => {
          if (result.value && result.value == true) {
            this.diffObjects("initialDatesETD");
          } else {
            if (this.initialDatesETD == null) {
              this.initialDatesETD = null;
            }
          }
        });
      }
    },
    async diffObjects(fieldName) {
      const newModel = {
        ...this.process,
      };
      newModel[fieldName] = this[fieldName];

      await this.$store.dispatch("ProcessStore/diffObjects", {
        initialModel: {
          ...this.process,
        },
        model: newModel,
        fieldName: fieldName,
      });
    },

    updateAttrs(process) {
      this.datesEstimatedGoodsReadinesDate =
        process.datesEstimatedGoodsReadinesDate;
      this.datesETD = process.datesETD;
      this.initialDatesETD = process.initialDatesETD;
      this.datesETA = process.datesETA;
      this.datesEstimatedCustomerDeliverDate =
        process.datesEstimatedCustomerDeliverDate;
      this.datesGoodsReadinesDate = process.datesGoodsReadinesDate;
      this.datesDepartureDate = process.datesDepartureDate;
      this.mooringDate = process.mooringDate;
      this.datesArrivalDate = process.datesArrivalDate;
      this.datesGoodsPresenceDate = process.datesGoodsPresenceDate;
      this.datesInspectionOfTheDIDate = process.datesInspectionOfTheDIDate;
      this.datesGoodsLoadingDate = process.datesGoodsLoadingDate;
      this.datesCustomerDeliverDate = process.datesCustomerDeliverDate;
      this.datesInspectionMAPADate = process.datesInspectionMAPADate;
      this.datesInspectionMAPADateSolved =
        process.datesInspectionMAPADateSolved;
      this.datesReleaseMAPADate = process.datesReleaseMAPADate;
      this.datesReleaseMAPADateSolved = process.datesReleaseMAPADateSolved;
      this.datesLinkInTheEnclosureDate = process.datesLinkInTheEnclosureDate;
      this.datesLinkInTheEnclosureDateSolved =
        process.datesLinkInTheEnclosureDateSolved;
      this.datesSiscargaState = process.datesSiscargaState;
      this.datesSiscargaDate = process.datesSiscargaDate;
      this.datesBillOfLadingReleaseDate = process.datesBillOfLadingReleaseDate;
      this.datesTFASolved = process.datesTFASolved;
      this.datesIsThereDTCDTATransfer = process.datesIsThereDTCDTATransfer;
      this.datesDTCDTA = process.datesDTCDTA;
      this.datesThereIsDesova = process.datesThereIsDesova;
      this.datesDesova = process.datesDesova;
      this.datesEnclosureBillingDate = process.datesEnclosureBillingDate;
      this.datesComexDate = process.datesComexDate;
      this.legacyId = process.legacyId;
      this.legacyCreated = process.legacyCreated;
      this.legacyCreatedBy = process.legacyCreatedBy;
      this.created = process.created;
      this.createdBy = process.createdBy;
      this.estimatedStepCashRequest = process.estimatedStepCashRequest;
      this.estimatedInvoiceDate = process.estimatedInvoiceDate;
    },
  },
  computed: {
    ...mapState({
      process: (state) => state.ProcessStore.activeProcess,
      freightQuotationsResponses: (state) => state.ProcessStore.freightQuotationsResponses,
    }),
  },
  async created() {
    this.updateAttrs(this.process);
    await this.calculateTransitTime();
  },
  async mounted(){
    this.getFreightQuotationsResponses(this.process.id);
    await this.calculateTransitTime();
  },
  watch: {
    process: function (newVal, oldVal) {
      
      if(newVal.datesEstimatedGoodsReadinesDate != oldVal.datesEstimatedGoodsReadinesDate){
        this.estimatedInvoiceDate = newVal.datesEstimatedGoodsReadinesDate;
        this.diffObjects("estimatedInvoiceDate");
      }

      this.getFreightQuotationsResponses(newVal.id);
      this.calculateTransitTime();
      this.updateAttrs(newVal);
    },
    freightQuotationsResponses: function () {
      this.calculateTransitTime();
    },
    estimatedInvoiceDate: function () {
      this.diffObjects("estimatedInvoiceDate");
    },
    estimatedStepCashRequest: function () {
      this.diffObjects("estimatedStepCashRequest");
    },
    datesEstimatedGoodsReadinesDate: function () {
      this.diffObjects("datesEstimatedGoodsReadinesDate");
    },
    datesETD: function () {
      this.diffObjects("datesETD");
    },
    datesETA: function () {
      this.diffObjects("datesETA");
    },
    datesEstimatedCustomerDeliverDate: function () {
      this.diffObjects("datesEstimatedCustomerDeliverDate");
    },
    datesGoodsReadinesDate: function () {
      this.diffObjects("datesGoodsReadinesDate");
    },
    datesDepartureDate: function () {
      this.diffObjects("datesDepartureDate");
    },
    mooringDate: function () {
      this.diffObjects("mooringDate");
    },
    datesArrivalDate: function () {
      this.diffObjects("datesArrivalDate");
    },
    datesGoodsPresenceDate: function () {
      this.diffObjects("datesGoodsPresenceDate");
    },
    datesInspectionOfTheDIDate: function () {
      this.diffObjects("datesInspectionOfTheDIDate");
    },
    datesGoodsLoadingDate: function () {
      this.diffObjects("datesGoodsLoadingDate");
    },
    datesCustomerDeliverDate: function () {
      this.diffObjects("datesCustomerDeliverDate");
    },
    datesInspectionMAPADate: function () {
      this.diffObjects("datesInspectionMAPADate");
    },
    datesInspectionMAPADateSolved: function () {
      this.diffObjects("datesInspectionMAPADateSolved");
    },
    datesReleaseMAPADate: function () {
      this.diffObjects("datesReleaseMAPADate");
    },
    datesReleaseMAPADateSolved: function () {
      this.diffObjects("datesReleaseMAPADateSolved");
    },
    datesLinkInTheEnclosureDate: function () {
      this.diffObjects("datesLinkInTheEnclosureDate");
    },
    datesLinkInTheEnclosureDateSolved: function () {
      this.diffObjects("datesLinkInTheEnclosureDateSolved");
    },
    datesSiscargaState: function () {
      this.diffObjects("datesSiscargaState");
    },
    datesSiscargaDate: function () {
      this.diffObjects("datesSiscargaDate");
    },
    datesBillOfLadingReleaseDate: function () {
      this.diffObjects("datesBillOfLadingReleaseDate");
    },
    datesTFASolved: function () {
      this.diffObjects("datesTFASolved");
    },
    datesIsThereDTCDTATransfer: function () {
      this.diffObjects("datesIsThereDTCDTATransfer");
    },
    datesDTCDTA: function () {
      this.diffObjects("datesDTCDTA");
    },
    datesThereIsDesova: function () {
      this.diffObjects("datesThereIsDesova");
    },
    datesDesova: function () {
      this.diffObjects("datesDesova");
    },
    datesEnclosureBillingDate: function () {
      this.diffObjects("datesEnclosureBillingDate");
    },
    datesComexDate: function () {
      this.diffObjects("datesComexDate");
    },
    legacyId: function () {
      this.diffObjects("legacyId");
    },
    legacyCreated: function () {
      this.diffObjects("legacyCreated");
    },
    legacyCreatedBy: function () {
      this.diffObjects("legacyCreatedBy");
    },
    created: function () {
      this.diffObjects("created");
    },
    createdBy: function () {
      this.diffObjects("createdBy");
    },
  },
};
</script>

<style lang="scss" scoped>
.form-layout-2 .form-dates-sidebar {
  height: 100vh;

  .form-group {
    height: initial;

    & > label {
      min-width: 120px;
      margin: 0 20px 0 0;
    }
  }
}
</style>
