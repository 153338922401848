<template>
  <div class="slim-mainpanel">
    <div class="container pd-t-30">
      <div class="dash-headline-two">
        <div class="d-flex align-items-center">
          <h2 class="tx-inverse mg-b-0">
            Integrações de Parceiros (Freight Forwarders)
          </h2>
          <p class="mg-b-0 mg-l-20">
            Operacional /
            <strong>Integrações de Parceiros (Freight Forwarders)</strong>
          </p>
        </div>
      </div>
    </div>

    <PartnerIntegrationsFilter @find-processes-with-data="getProcesses"/>

    <div class="container pd-t-30 ov-h height-calc">
      <div class="columns md-gap">
        <!-- Col 1 -->
        <div class="span-8 bd height-calc-h ov-h">
          <div class="light-bg p-3 bd-b">
            <div :class="['flex ai-c jc-sb fw-w fgap2']">
              <div>
                <h4 class="mb-0 text-dark text-bold">
                  INTEGRAÇÕES RECEBIDAS DOS FORWARDERS
                </h4>
              </div>
              <div>
                <button type="button" class="crm-button square gray" @click="getProcesses">
                  <div class="flex ai-c fgap1">
                    <refresh-icon color="#fff"/>
                    <span class="text-bold">Recarregar</span>
                  </div>
                </button>
              </div>
            </div>
          </div>

          <div class="columns no-gap">
            <div class="span-3 light-bg bd-r height-calc-h-i ov-h flex fd-c">
              <form @submit.prevent class="search-contacts">
                <search-icon/>

                <input
                  v-model="processSearch"
                  type="text"
                  placeholder="Pesquisar Processo"
                />

                <drop-down contentPosition="right" label="Filtros" buttonSize="md" :content-width="300" v-if="filteredProcesses.length > 0">
                  <template #content>
                    <div class="p-3 flex fd-c fgap1">
                      <v-radio name="filter" label="Recentes" radioValue="RECENT" v-model="sortFilter" @input="sortProcesses" />
                      <v-radio name="filter" label="Processos não Visualizados" radioValue="UNREADED" v-model="sortFilter" @input="sortProcesses" />
                      <v-radio name="filter" label="Processos Visualizados" radioValue="READED" v-model="sortFilter" @input="sortProcesses" />
                      <v-radio name="filter" label="Itens (dados) não visualizados" radioValue="ITENS_NOT_READED" v-model="sortFilter" @input="sortProcesses" />
                    </div>
                  </template>
                </drop-down>
              </form>

              <ul class="process-list">
                <!-- No Results -->
                <li
                  class="pd-10 text-center flex fd-c jc-c no-hover"
                  style="min-height: 250px"
                  v-if="filteredProcesses.length == 0"
                >
                  <p class="text-bold mb-0 text-dark">
                    Nenhum processo com integração disponível no momento
                  </p>
                </li>
                <!-- No Results -->

                <template v-if="filteredProcesses.length > 0">
                  <li
                    v-for="process in filteredProcesses"
                    :key="process.gescomexId"
                    :class="{
                      active: process.identification === selectedProcess
                    }"
                  >
                    <a href="#" @click.prevent="getProcessData(process.identification, process.forwarderName)">
                      <div class="flex ai-c fgap1">
                        <p class="mb-0 text-dark tx-16"><strong>PRI{{ process.identification }}</strong></p>
                        <span class="text-tag-thin text-dark">
                          <calendar-icon :size="13"/>
                          {{ process.lastDataSendedAt | formatDate }}
                        </span>
                        <span class="text-tag-thin float-right bg-warning text-dark" v-if="process.unviewedDataCount > 0">
                          {{ process.unviewedDataCount }} itens não visualizados.
                        </span>
                      </div>
                      <div class="mt-1">
                        <span class="text-dark uppercase tx-16">{{ process.customerName }}</span>
                        <span> / </span>
                        <span class="text-dark text-bold">{{ process.forwarderName }}</span>
                      </div>
                      <div v-if="process.viewed">
                        <span class="text-dark"><i class="fa fa-eye text-primary mr-1"></i> Visualizado pela última vez em {{ process.isViewedAt | formatDate }} por {{ process.isViewedBy }} </span>
                      </div>
                      <div v-else>
                        <span><i class="fa fa-exclamation-triangle text-warning"></i> Não visualizado</span>
                      </div>
                    </a>
                  </li>
                </template>
              </ul>
            </div>

            <div class="span-5 p-2 height-calc-h-i ov-h flex fd-c offwhite-bg">
              <div
                class="integrations-list-header white-bg flex ai-c jc-sb fgap2 ph-2 pv-2 mb-2"
              >
                <div class="text-center text-dark flex1">
                  <p class="mb-0 text-thin">DADOS</p>
                  <p class="mb-0 text-bold text-nm">RECEBIDOS</p>
                </div>

                <div class="text-center text-dark flex1">
                  <p class="mb-0 text-bold text-md" v-if="selectedProcess">PRI{{ selectedProcess }}</p>
                  <p class="mb-0 text-thin">NOME DO FORWARDER</p>
                  <p class="mb-0 text-bold">{{ forwarderSelected ? forwarderSelected : '-' }}</p>
                </div>

                <div class="text-center text-dark flex1">
                  <p class="mb-0 text-thin">DADOS</p>
                  <p class="mb-0 text-bold text-nm">ATUAIS</p>
                </div>
              </div>

              <ul class="integrations-list pt-2">
                <!-- No Results -->
                <li
                  v-if="processData.length == 0"
                  class="pd-10 text-center flex fd-c jc-c no-border"
                  style="min-height: 250px"
                >
                  <p
                    class="text-bold mb-0 text-dark"
                    style="position: relative; z-index: 8"
                  >
                    Nenhuma integração disponível no momento
                  </p>
                </li>
                <!-- No Results -->
                <template v-if="processData.length > 0">
                  <li
                    v-for="(data, key) in processData"
                    :key="key"
                    class="flex jc-sb gap2 pb-2 pt-4 mt-2 ph-1"
                    :class="data.STATUS === 'INCONSISTENCY' ? 'inconsistency' : 'bg-white'"
                  >
                    <div class="received bd br-10 p-2 pt-3 bg-white relative">
                      <div
                        class="item-head"
                        :class="data.SENDED.approved === true ? 'dark' : ''"
                      >
                        {{ data.SENDED.field | formatField }}
                      </div>

                      <div class="item-content pt-2 text-center">
                        <p class="text-md text-bold mb-0"
                           :class="data.SENDED.approved === true ? 'text-success' : 'text-dark'">
                          {{ getDataValue(data.SENDED) }}
                        </p>
                        <p class="mb-0">
                          Enviado por <strong>{{ data.SENDED.sendedBy }}</strong>
                        </p>
                        <p class="mb-2">
                          Em <strong>{{ data.SENDED.sendedAt | formatDate }}</strong>
                        </p>
                        <span class="text-status danger"
                              v-if="data.STATUS === 'INCONSISTENCY' && ((Object.keys(data.SENDED.approved).length === 0 && data.SENDED.approved.constructor === Object) || data.SENDED.approved == null)">
                          INCONSISTÊNCIAS
                        </span>
                        <span class="text-status warning"
                              v-if="data.STATUS !== 'INCONSISTENCY' && Object.keys(data.SENDED.approved).length === 0 && data.SENDED.approved.constructor === Object">
                          PENDENTE
                        </span>
                        <span class="text-status danger" v-if="data.SENDED.approved === false">
                          REJEITADO
                        </span>
                        <span class="text-status success" v-if="data.SENDED.approved === true">
                          INTEGRADO
                        </span>

                        <a v-if="data.SENDED.justification !== null && data.SENDED.justification !== 'null'" href="#"
                           class="justification-btn"
                           @click.prevent="openJustification(data)">
                          <i class="fa fa-info"></i>
                        </a>
                      </div>
                    </div>

                    <div class="actions flex fd-c fgap1 ai-c">
                      <div class="number-indicator">{{ key + 1 }}</div>
                      <div
                        v-if="Object.keys(data.SENDED.approved).length === 0 && data.SENDED.approved.constructor === Object">
                        <a
                          v-if="data.SENDED.field !== 'CONTAINERS'"
                          @click.prevent="approveProcessData(data)"
                          href="#"
                          class="crm-button small round block text-center"
                        >
                          Aprovar
                        </a>
                        <a
                          v-else
                          @click.prevent="() => checkContainers(data, data.PROCESS_ID)"
                          href="#"
                          class="crm-button small round block text-center"
                        >
                          Analisar aprovação
                        </a>
                        <a
                          @click.prevent="rejectProcessData(data)"
                          href="#"
                          class="crm-button small round block text-center red mt-1"
                        >
                          Contestar
                        </a>
                      </div>
                      <div v-else-if="!data.SENDED.approved">
                        <span class="text-status danger">
                          CONTESTADO
                        </span>
                      </div>
                      <div v-else>
                        <span class="text-status success">
                          APROVADO
                        </span>
                      </div>
                    </div>

                    <div class="actual white-bg bd br-10 p-2 pt-3">
                      <div
                        class="item-head"
                        :class="data.SENDED.approved === true ? 'dark' : ''"
                      >
                        {{ data.SENDED.field | formatField }}
                      </div>

                      <div class="item-content pt-2 text-center">
                        <p class="text-md text-bold mb-0"
                           :class="data.SENDED.approved === true ? 'text-success' : 'text-dark'">
                          <span v-if="data.DESTINATION_PORT !== null && data.DESTINATION_PORT">{{ data.CURRENT }}</span>
                          <span v-else>{{ data.CURRENT ? getCurrentDataValue(data) : '-' }}</span>
                        </p>
                        <div
                          v-if="(data.SENDED.approved === true || !data.SENDED.approved) && data.SENDED.integratedBy.constructor !== Object">
                          <p class="mb-0">
                            Enviado por <strong>{{ data.SENDED.integratedBy }}</strong>
                          </p>
                          <p class="mb-2">
                            Em <strong>{{ data.SENDED.integratedAt | formatDate }}</strong>
                          </p>
                        </div>
                        <div v-else>
                          <p class="mb-2">-</p>
                        </div>
                        <check-icon v-if="data.SENDED.approved === true" color="#3B9B28"/>
                        <x-mark-icon v-else-if="data.SENDED.approved === false" color="red">-</x-mark-icon>
                        <span v-else>NENHUMA INTEGRAÇÃO RECEBIDA</span>
                      </div>
                    </div>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Justificativa -->
    <v-modal
      @requestClose="closeJustification"
      :handler="justificationModalOpen"
      title="Justificativa do envio"
      description=""
      id="modalJustification"
      :width="700"
      :height="40"
    >
      <template #content>
        <p>{{ justificationData.SENDED.justification }}</p>
      </template>
    </v-modal>
    <!-- Modal Justificativa -->

    <v-modal
      title="Analisar containers"
      :description="`Compare os containers cadastrados com os enviados entes de aprovar`"
      :height="40"
      :width="800"
      :handler="openModalCheckContainers"
      @requestClose="openModalCheckContainers = false"
    >
      <template #content>
        <div>
          <div class="alert alert-warning">
            <i class="fa fa-exclamation-triangle mr-2"></i> Atenção! ao aprovar estas informações, os dados dos
            containers serão alterados.
          </div>
          <div class="col-12">
          </div>
          <div class="row">
            <div class="col-6">
              <table class="table table-striped table-hover table-bordered">
                <thead>
                <tr>
                  <th class="text-center">Recebidos</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="container in containersSended" :key="container.number">
                  <td>
                    <p><strong>Container: </strong>{{ container.container }}</p>
                    <p><strong>Chegada: </strong>{{ container.arrival ? formatDateFunction(container.arrival) : 'N/A' }}
                    </p>
                    <p><strong>Freetime: </strong>{{ container.freetime ? container.freetime : 'N/A' }}</p>
                    <p><strong>Deadline: </strong>{{
                        container.deadline ? formatDateFunction(container.deadline) : 'N/A'
                      }}</p>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="col-6">
              <table class="table table-striped table-hover table-bordered">
                <thead>
                <tr>
                  <th class="text-center">Atuais</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="containersCurrent.length == 0">
                  <td class="text-center">
                    Nenhum container cadastrado atualmente.
                  </td>
                </tr>
                <template v-else>
                  <tr v-for="container in containersCurrent" :key="container.number">
                    <td>
                      <p><strong>Container: </strong>{{ container.container }}</p>
                      <p><strong>Chegada: </strong>{{
                          container.arrival ? formatDateFunction(container.arrival) : 'N/A'
                        }}
                      </p>
                      <p><strong>Freetime: </strong>{{ container.freetime ? container.freetime : 'N/A' }}</p>
                      <p><strong>Deadline: </strong>{{
                          container.deadline ? formatDateFunction(container.deadline) : 'N/A'
                        }}</p>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </template>
      <template #footer="{ closeModal }">
        <div class="d-flex align-items-center justify-content-center">
          <button class="crm-button danger round mr-2" id="closeModalContainers" @click.prevent="closeModal">CANCELAR
          </button>
          <button class="crm-button round" @click.prevent="approveProcessData(containersDataSelected)">APROVAR
            CONTAINERS RECEBIDOS
          </button>
        </div>
      </template>
    </v-modal>

    <v-modal title="Alteração de Datas e Histórico"
             :description="`Alterar demais datas do Processo e registrar Histórico`"
             :handler="modalHistoryOpen"
             @requestClose="closeHistoryModal"
             :height="70"
             :width="600">
      <template #content>
        <form action="" class="form-layout form-layout-2">
          <span>Deseja Alterar algum outro campo de Data?</span>
          <div class="row no-gutters mt-2">
            <div class="form-group col-md-3">
              <label class="form-control-label"> ETD Inicial </label>
              <div class="input-group-solved">
                <div class="input-group-solved-before">
                  <i class="fa fa-calendar op-5"></i>
                </div>
                <div class="input-group-solved-center">
                  <date-picker v-model="formHistory.initialDatesETD" name="initialDatesETD" inputName="initialDatesETD"
                               class="form-control" format="DD/MM/YY" lang="pt-br"
                               placeholder="Selecione"></date-picker>
                </div>
              </div>
            </div>
            <div class="form-group col-md-3 bd-l-0-force">
              <label class="form-control-label"> ETD </label>
              <div class="input-group-solved">
                <div class="input-group-solved-before">
                  <i class="fa fa-calendar op-5"></i>
                </div>
                <div class="input-group-solved-center">
                  <date-picker v-model="formHistory.datesETD" name="datesETD" inputName="datesETD" class="form-control"
                               format="DD/MM/YY" lang="pt-br" placeholder="Selecione"></date-picker>
                </div>
              </div>
            </div>
            <div class="form-group col-md-3 bd-l-0-force">
              <label class="form-control-label"> ETA </label>
              <div class="input-group-solved">
                <div class="input-group-solved-before">
                  <i class="fa fa-calendar op-5"></i>
                </div>
                <div class="input-group-solved-center">
                  <date-picker v-model="formHistory.datesETA" name="datesETA" inputName="datesETA" class="form-control"
                               format="DD/MM/YY" lang="pt-br" placeholder="Selecione"></date-picker>
                </div>
              </div>
            </div>
            <div class="form-group col-md-3 bd-l-0-force">
              <label class="form-control-label"> Entrega Cliente </label>
              <div class="input-group-solved">
                <div class="input-group-solved-before">
                  <i class="fa fa-calendar op-5"></i>
                </div>
                <div class="input-group-solved-center">
                  <date-picker v-model="formHistory.datesEstimatedCustomerDeliverDate"
                               name="datesEstimatedCustomerDeliverDate" inputName="datesEstimatedCustomerDeliverDate"
                               class="form-control" format="DD/MM/YY" lang="pt-br"
                               placeholder="Selecione"></date-picker>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div class="mt-2">
            <div class="row">
              <div class="col-6">
                <label>Enviar Histórico?</label>
                <v-switch
                  name="sendHistory"
                  :labels="{
                      checked: `Sim`,
                      unchecked: `Não`,
                    }"
                  v-model="formHistory.sendHistory"
                />
              </div>
              <div class="col-6" v-if="formHistory.sendHistory">
                <label>Visível ao Cliente?</label>
                <v-switch
                  name="visibleToCustomer"
                  :labels="{
                      checked: `Sim`,
                      unchecked: `Não`,
                    }"
                  v-model="formHistory.isVisibleToCustomer"
                />
              </div>
            </div>
          </div>
          <div v-if="formHistory.sendHistory" class="mt-3">
            <virtual-select tabindex="0" placeholder="Selecione uma Prioridade" :options="priorityOptions"
                            v-model="formHistory.historyPriority"/>
          </div>
          <div class="mt-3" v-if="formHistory.sendHistory">
            <v-textarea name="historyText" label="Texto do Histórico" v-model="formHistory.historyContent"></v-textarea>
          </div>
        </form>
      </template>
      <template #footer>
        <div class="row justify-content-center">
          <button class="crm-button mt-2 mr-2 gray" @click.prevent="closeHistoryModal">Fechar</button>
          <button class="crm-button mt-2" @click.prevent="submitOtherDatesAndHistoryForm">Salvar</button>
        </div>
      </template>
    </v-modal>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import {SearchIcon, CalendarIcon} from "@/components/crm/Icons";
import RefreshIcon from "@/components/icons/Refresh.vue";
import CheckIcon from "@/components/icons/Check.vue";
import XMarkIcon from "@/components/icons/XMark.vue";
import PartnerIntegrationsFilter from "@/components/PartnerIntegrationsFilter.vue";

import VModal from "@/components/VModal.vue";

import config from "@/config.js";
import Axios from 'axios';

import moment from 'moment';
import Swal from 'sweetalert2';
import VirtualSelect from "@/components/VirtualSelect.vue";
import VSwitch from "@/components/VSwitch.vue";
import VTextarea from "@/components/VTextarea.vue";
import DropDown from '@/components/DropDown.vue';
import VRadio from '@/components/VRadio.vue';

export default {
  name: "PartnerIntegrations",
  components: {
    DatePicker,
    VSwitch,
    VirtualSelect,
    XMarkIcon,
    PartnerIntegrationsFilter,
    RefreshIcon,
    SearchIcon,
    CalendarIcon,
    CheckIcon,
    VModal,
    VTextarea,
    DropDown,
    VRadio
  },
  data() {
    return {
      sortFilter: "RECENT",
      processes: [],
      processData: [],
      forwarderSelected: null,
      openModalCheckContainers: false,
      containersSended: [],
      containersCurrent: [],
      containersDataSelected: null,
      last10Quotations: [],
      justificationModalOpen: false,
      justificationData: null,
      selectedProcess: null,
      selectedProcessId: null,
      processSearch: '',
      modalHistoryOpen: false,
      fieldToApprove: null,
      formHistory: {
        processId: null,
        datesETA: null,
        datesETD: null,
        initialDatesETD: null,
        datesEstimatedCustomerDeliverDate: null,
        sendHistory: false,
        historyPriority: null,
        historyContent: null,
        isVisibleToCustomer: false
      },
      stateFilters: {},
      priorityOptions: [
        {
          label: 'Normal',
          value: 'NORMAL',
        },
        {
          label: 'Importante',
          value: 'IMPORTANTE',
        },
        {
          label: 'Urgente',
          value: 'URGENTE',
        },
      ],
    }
  },
  methods: {
    openJustification(data) {
      this.justificationModalOpen = true;
      this.justificationData = data;
    },
    closeJustification() {
      this.justificationModalOpen = false;
      this.justificationData = null;
    },
    async getProcesses(filters) {
      this.$store.commit('setLoading', {
        show: true,
        label: `Buscando processos`,
        description: 'Aguarde, estamos buscando os processos que receberam dados!'
      });

      try {
        const params = {};

        if (filters.customer !== null && filters.customer !== undefined) {
          params.customerId = filters.customer.id;
        }

        const response = await Axios.get(`${config.env.API_DEFAULT.host}/forwarders-integrations/processes/processes-with-data`, {
          params: filters
        });

        this.stateFilters = filters;

        this.processes = response.data.data;

        this.$store.commit('setLoading', {
          show: false,
        });
      } catch (e) {

        this.$store.commit('setLoading', {
          show: false,
        });
      }
    },
    async getProcessDataList() {
      this.$store.commit('setLoading', {
        show: true,
        label: `Buscando dados enviados`,
        description: 'Aguarde, estamos buscando a listagem geral de dados recebidos!'
      });

      try {
        const response = await Axios.get(`${config.env.API_DEFAULT.host}/forwarders-integrations/process-data`);

        this.processData = response.data.data;

        this.$store.commit('setLoading', {
          show: false,
        });
      } catch (e) {

        this.$store.commit('setLoading', {
          show: false,
        });
      }
    },
    async checkContainers(data, processId) {
      this.$store.commit('setLoading', {
        show: true,
        label: `Buscando os containers do processo`,
        description: 'Aguarde, estamos buscando os containers cadastrados!'
      });

      this.containersSended = JSON.parse(data.SENDED.value);
      this.openModalCheckContainers = true;
      this.containersDataSelected = data;

      try {
        const response = await Axios.get(`${config.env.API_DEFAULT.host}/process/import/${processId}/containers/find-by-process-id`);

        this.containersCurrent = response.data.data;

        this.$store.commit('setLoading', {
          show: false,
        });
      } catch (e) {
        this.$store.commit('setLoading', {
          show: false,
        });
      }
    },
    formatDateFunction(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    async getProcessData(identification, forwarderName) {
      this.selectedProcess = identification;
      this.$store.commit('setLoading', {
        show: true,
        label: `Buscando dados do processo`,
        description: 'Aguarde, estamos buscando os dados enviados para o processo!'
      });

      this.forwarderSelected = forwarderName;

      try {
        const response = await Axios.get(`${config.env.API_DEFAULT.host}/forwarders-integrations/process-data/${identification}`);

        this.processData = response.data.data.sort((data1, data2) => {
          return new Date(data2.SENDED.sendedAt) - new Date(data1.SENDED.sendedAt);
        });

        await this.getProcesses(this.stateFilters);

        this.$store.commit('setLoading', {
          show: false,
        });
      } catch (e) {
        this.$store.commit('setLoading', {
          show: false,
        });
      }
    },
    async approveProcessData(data) {
      this.selectedProcessId = data.PROCESS_ID;
      this.fieldToApprove = data.SENDED.field;

      Swal.fire({
        title: 'Aprovação de dados',
        html: `<p>Você tem certeza que deseja aprovar esta informação?</p>`,
        type: 'warning',
        input: "textarea",
        inputPlaceholder: "Observações da aprovação: ",
        showConfirmButton: true,
        confirmButtonText: 'Aprovar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (!result.dismiss) {
          this.$store.commit('setLoading', {
            show: true,
            label: `Aprovação de dados`,
            description: 'Aguarde, estamos atualizando a informação no processo!'
          });
          try {
            const response = await Axios.post(`${config.env.API_DEFAULT.host}/forwarders-integrations/process-data/approve/${data.PROCESS_ID}`, {
              field: data.SENDED.field,
              value: data.SENDED.value,
              destinationPortValue: data.SENDED.destinationPortCodeIso,
              processDataId: data.SENDED.id,
              sendedBy: data.SENDED.sendedBy,
              sendedByEmail: data.SENDED.sendedByEmail,
              sendedAt: data.SENDED.sendedAt,
              justification: result.value
            });

            this.$store.commit('setLoading', {
              show: false,
            });

            if (data.SENDED.field === 'CONTAINERS') {
              document.getElementById('closeModalContainers').click();
            }

            Swal.fire({
              title: "Aprovação de dados",
              html: `<p>Dado aprovado com sucesso!</p>`,
              type: "success",
            });

            await this.getProcessData(response.data.data.identification);

            let field = data.SENDED.field;

            // Após a aprovação, abrir o modal de histórico
            if (field === 'ETA' || field === 'ETD' || field === 'BOARDING' || field === 'ARRIVAL' || field === 'ESTIMATE_READINESS') {
              this.modalHistoryOpen = true;

              this.formHistory.datesETA = response.data.data.datesETA;
              this.formHistory.datesETD = response.data.data.datesETD;
              this.formHistory.initialDatesETD = response.data.data.initialDatesETD;
              this.formHistory.datesEstimatedCustomerDeliverDate = response.data.data.datesEstimatedCustomerDeliverDate;
              this.formHistory.processId = response.data.data.id;
            }

          } catch (e) {
            console.log(e);

            this.$store.commit('setLoading', {
              show: false,
            });

            Swal.fire({
              title: "Aprovação de dados",
              html: `<p>Houve um erro inesperado ao aprovar informação!</p>`,
              type: "error",
            });
          }
        }
      })
    },
    async closeHistoryModal() {
      this.modalHistoryOpen = false;

      this.clearHistoryForm();

      const dateFields = {
        "ETD": "datesETD",
        "ETA": "datesETA",
        "ARRIVAL": "datesArrivalDate",
        "BOARDING": "datesDepartureDate",
        "ESTIMATE_READINESS": "datesEstimatedGoodsReadinesDate",
      }

      let field = dateFields[this.fieldToApprove];

      await this.checkEvents(this.selectedProcessId, field);
    },
    async checkEvents(processId, field) {
      const checkEventResponse = await Axios.get(config.env.API_DEFAULT.host + "/process/import/checkEvent/" + processId + "/" + field + "/check/true");

      if (checkEventResponse.status === 200) {

        if (checkEventResponse.data.listOfMessages.length > 0) {
          // Verifica se o usuário quer enviar evento
          Swal.fire({
            title: 'Eventos do Processo',
            html: `<p>Os eventos abaixo foram encontrados, você deseja dispará-los?</p>` +
              `<div class="card"><div class="card-body">${checkEventResponse.data.listOfMessages[0]}</div></div>`,
            type: 'warning',
            showConfirmButton: true,
            confirmButtonText: 'Enviar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
          }).then(async (result) => {
            if (result.value) {
              this.$store.commit("setLoading", {
                show: true,
                label: "Eventos de Processo",
                description: "Enviando o seguinte evento: " + checkEventResponse.data.listOfMessages[0]
              });

              // Faz o disparo do evento
              try {
                const responseSendEvent = await Axios.get(config.env.API_DEFAULT.host + "/process/import/checkEvent/" + processId + "/" + field + "/check/false");

                if (responseSendEvent.data.data[0] !== undefined && responseSendEvent.data.data[0] !== null) {
                  if (
                    responseSendEvent.data.data[0].actions !== undefined
                    && responseSendEvent.data.data[0].actions !== null
                    && responseSendEvent.data.data[0].actions.CHANGE_STEP !== undefined
                    && responseSendEvent.data.data[0].actions.CHANGE_STEP !== null
                  ) {
                    const step = responseSendEvent.data.data[0].actions.CHANGE_STEP;

                    const changeStepResponse = await Axios.put(config.env.API_DEFAULT.host + '/newImportProcess/' + processId + '/step/' + step.id);

                    Swal.fire({
                      type: "success",
                      title: "Eventos do Processo " + changeStepResponse.data.data.identification,
                      html: `O Follow UP foi enviado com sucesso e a etapa do processo foi alterada para <strong>${step.description}</strong>`,
                      showConfirmButton: true,
                      timer: 5000,
                    });
                  } else {
                    Swal.fire({
                      type: "success",
                      title: "Eventos do Processo",
                      html: responseSendEvent.data.data[0].message,
                      showConfirmButton: true,
                      timer: 5000,
                    });
                  }
                }

                this.$store.commit("setLoading", {
                  show: false,
                });
              } catch (e) {
                Swal.fire({
                  type: "error",
                  title: "Houve um erro inesperado ao enviar o Follow UP: " + e.response.data.message,
                  timer: 5000,
                });
              }
            }

            this.$store.commit("setLoading", {
              show: false,
            });
          });
        }
      }
    },
    async rejectProcessData(data) {

      Swal.fire({
        title: 'Rejeição de dados',
        html: `<p>Você tem certeza que deseja contestar esta informação?</p>`,
        type: 'warning',
        input: "textarea",
        inputPlaceholder: "Informe uma justificativa: ",
        showConfirmButton: true,
        confirmButtonText: 'Contestar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        console.log('Resultado da contestação', result);
        if (result.dismiss === undefined) {
          this.$store.commit('setLoading', {
            show: true,
            label: `Contestação de Dados`,
            description: 'Aguarde, estemos executando a rejeição!'
          });

          try {
            const response = await Axios.post(`${config.env.API_DEFAULT.host}/forwarders-integrations/process-data/reject/${data.PROCESS_ID}`, {
              field: data.SENDED.field,
              value: data.SENDED.value,
              processDataId: data.SENDED.id,
              sendedBy: data.SENDED.sendedBy,
              sendedByEmail: data.SENDED.sendedByEmail,
              sendedAt: data.SENDED.sendedAt,
              justification: result.value
            });

            this.$store.commit('setLoading', {
              show: false,
            });

            Swal.fire({
              title: "Contestação de Dados",
              html: `<p>Dado contestado com sucesso!</p>`,
              type: "success",
            });

            this.getProcessData(response.data.data.identification);
          } catch (e) {
            this.$store.commit('setLoading', {
              show: false,
            });

            Swal.fire({
              title: "Contestação de Dados",
              html: `<p>Houve um erro inesperado ao contestar informação!</p>`,
              type: "error",
            });
          }
        }
      })
    },
    getDataValue(data) {
      if (
        data.field === 'ETD'
        || data.field === 'ETA'
        || data.field === 'ARRIVAL'
        || data.field === 'READINESS'
        || data.field === 'ESTIMATE_READINESS'
        || data.field === 'BOARDING'
        || data.field === 'MOORING'
        || data.field === 'SISCARGA'
        || data.field === 'MAPA'
      ) {
        return moment(data.value).format('DD/MM/yyyy');
        // return data.value;
      }

      if (data.field === 'CONTAINERS') {
        return JSON.parse(data.value).length;
      }

      return data.value;
    },
    getCurrentDataValue(data) {
      if (
        data.SENDED.field === 'ETD'
        || data.SENDED.field === 'ETA'
        || data.SENDED.field === 'ARRIVAL'
        || data.SENDED.field === 'READINESS'
        || data.SENDED.field === 'ESTIMATE_READINESS'
        || data.SENDED.field === 'BOARDING'
        || data.SENDED.field === 'MOORING'
        || data.SENDED.field === 'SISCARGA'
        || data.SENDED.field === 'MAPA'
      ) {
        return moment(data.CURRENT).format('DD/MM/yyyy');
      }

      if (data.SENDED.field === 'DESTINATION_PORT') {
        return data.CURRENT.alias + ' - ' + data.CURRENT.codeIso;
      }

      if (data.SENDED.field === 'CONTAINERS') {
        return data.CURRENT;
      }

      return data.CURRENT;
    },
    async submitOtherDatesAndHistoryForm() {
      this.$store.commit('setLoading', {
        show: true,
        label: `Salvando dados do Processo`,
        description: 'Aguarde, estamos salvando as informações de data e histórico!'
      });

      try {
        const data = {
          processId: this.formHistory.processId,
          datesETA: this.formHistory.datesETA !== null && this.formHistory.datesETA !== ''
            ? moment(this.formHistory.datesETA).format('Y-MM-DD') + 'T00:00:00'
            : null,
          datesETD: this.formHistory.datesETD !== null && this.formHistory.datesETD !== ''
            ? moment(this.formHistory.datesETD).format('Y-MM-DD') + 'T00:00:00'
            : null,
          initialDatesETD: this.formHistory.initialDatesETD !== null && this.formHistory.initialDatesETD !== ''
            ? moment(this.formHistory.initialDatesETD).format('Y-MM-DD') + 'T00:00:00'
            : null,
          datesEstimatedCustomerDeliverDate: this.formHistory.datesEstimatedCustomerDeliverDate !== null && this.formHistory.datesEstimatedCustomerDeliverDate !== ''
            ? moment(this.formHistory.datesEstimatedCustomerDeliverDate).format('Y-MM-DD') + 'T00:00:00'
            : null,
          sendHistory: this.formHistory.sendHistory,
          historyPriority: this.formHistory.historyPriority,
          historyContent: this.formHistory.historyContent,
          isVisibleToCustomer: this.formHistory.isVisibleToCustomer
        }

        const response = await Axios.post(`${config.env.API_DEFAULT.host}/forwarders-integrations/other-dates-and-history`, data);

        this.$store.commit('setLoading', {
          show: false,
        });

        if (
          data.sendHistory
          && (data.historyContent !== null && data.historyContent !== '')
          && data.isVisibleToCustomer
        ) {
          Swal.fire({
            title: "Enviar Follow UP",
            text: "Você acabou de adicionar um Histórico visível ao cliente, você deseja enviar o Follow-UP agora?",
            type: "success",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, enviar!",
            cancelButtonText: "Não"
          }).then(async (result) => {
            if (result.value) {
              this.$store.dispatch("ProcessStore/sendFollowUP", {
                id: data.processId,
                identification: this.selectedProcess
              }).then(async () => {
                await this.closeHistoryModal();
              });
            }

          });
        } else {
          Swal.fire({
            title: 'Dados do Processo',
            type: 'success',
            text: 'Dados salvos com sucesso!'
          });

          await this.closeHistoryModal();
        }

      } catch (e) {
        this.$store.commit('setLoading', {
          show: false,
        });
      }
    },
    clearHistoryForm() {
      this.formHistory.processId = null;
      this.formHistory.datesETA = null;
      this.formHistory.datesETD = null;
      this.formHistory.initialDatesETD = null;
      this.formHistory.datesEstimatedCustomerDeliverDate = null;

      this.formHistory.sendHistory = false;
      this.formHistory.historyPriority = null;
      this.formHistory.historyContent = null;
      this.formHistory.isVisibleToCustomer = false;
    },
    sortProcesses() {
      this.$store.commit('setLoading', {
        show: true,
      });

      if (this.sortFilter === 'RECENT') {
        this.processes = this.processes.sort((a, b) => new Date(b.lastDataSendedAt) - new Date(a.lastDataSendedAt));
      }

      if (this.sortFilter === 'UNREADED') {
        this.processes = this.processes.sort((a, b) => a.viewed - b.viewed);
      }

      if (this.sortFilter === 'READED') {
        this.processes = this.processes.sort((a, b) => b.viewed - a.viewed);
      }

      if (this.sortFilter === 'ITENS_NOT_READED') {
        this.processes = this.processes.sort((a, b) => b.unviewedDataCount - a.unviewedDataCount);
      }

      this.$store.commit('setLoading', {
        show: false,
      });
    }
  },
  filters: {
    formatDate: (date) => {
      return moment(date).locale('pt-br').format('DD/MM/YY [às] HH:mm');
    },
    formatDateOnly: (date) => {
      return moment(date).locale('pt-br').format('DD/MM/YY');
    },
    formatField: (field) => {
      if (field === 'BL_AWB') {
        return 'BL/AWB';
      }

      if (field === 'BL_AWB_MASTER') {
        return 'Master';
      }

      if (field === 'SHIP' || field === 'VEHICLE') {
        return 'Veículo';
      }

      if (field === 'VEHICLE_TRANSHIPMENT') {
        return 'Veículo de Embarque';
      }

      if (field === 'DESTINATION_PORT') {
        return 'Porto de destino';
      }

      if (field === 'TRANSIT_TIME') {
        return 'Transit time';
      }

      if (field === 'ESTIMATE_READINESS') {
        return 'Prontidão (Prevista)'
      }

      if (field === 'READINESS') {
        return 'Prontidão';
      }

      if (field === 'BOARDING') {
        return 'Embarque';
      }

      if (field === 'ARRIVAL') {
        return 'Chegada';
      }

      if (field === 'MOORING') {
        return 'Atracação';
      }

      return field;
    }
  },
  computed: {
    filteredProcesses() {
      const regExp = new RegExp(this.processSearch, 'gi');

      let processes = this.processes;

      if (this.processSearch !== '') {
        processes = this.processes.filter(item => regExp.test(item.identification) || regExp.test(item.customerName));
      }

      return processes;
    }
  },
  async mounted() {
    await this.getProcesses();
  }
};
</script>

<style scoped>
.search-contacts {
  background-color: white;
}

.height-calc {
  height: calc(100vh - 210px) !important;
}

.height-calc-h {
  height: calc(100vh - 240px) !important;
}

.height-calc-h-i {
  height: calc(100vh - 319px) !important;
}

.process-list,
.integrations-list,
.quotations-list {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: auto;
  height: 100%;
}

.process-list li {
  opacity: 0.75;
  transition: all 0.2s ease-in-out 0s;
}

.process-list li:not(.no-hover):hover,
.process-list li.active {
  opacity: 1;
  background-color: #e7e8ec;
}

.process-list li.visualized {
  opacity: 1;
  background-color: #e1e1e1;
}

.process-list li a {
  color: inherit;
  display: block;
  padding: 10px 20px;
}

.process-list li:not(:last-of-type) a {
  border-bottom: 1px solid #e2e2e2;
}

.integrations-list,
.integrations-list li {
  position: relative;
}

.integrations-list li.inconsistency {
  background-color: #e74c3c40;
}

.integrations-list li:not(.no-border)::before {
  content: "";
  display: block;
  width: 1px;
  position: absolute;
  top: -5px;
  bottom: -5px;
  left: calc(50% - 1px);
  /* margin: 0 auto; */
  border: 1px dashed #dee2e6;
  z-index: 2;
}

.integrations-list-header {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.integrations-list .received,
.integrations-list .actual {
  flex: 0 1 40%;
  position: relative;
}

.integrations-list .actions {
  flex: 0 0 22%;
  margin-left: -1%;
  margin-right: -1%;
  position: relative;
  z-index: 3;
}

.integrations-list .item-head {
  max-width: 80%;
  border-radius: 5px;
  background-color: #104375;
  color: #fff;
  font-weight: bold;
  padding: 3px 15px;
  text-align: center;
  font-size: 1rem;
  position: absolute;
  top: -15px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.integrations-list .item-head.dark {
  background-color: #343a40;
}

.number-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 99999px;
  width: 35px;
  height: 35px;
  background-color: #868ba1;
  color: #fff;
  font-weight: bold;
}

.justification-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid var(--primary);
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

@media screen and (max-width: 1330px) {
  .justification-btn {
    position: relative;
    bottom: unset;
    right: unset;
    margin: 1rem auto 0 auto;
  }
}

.quotation-type.navio,
.quotation-type.containerr {
  background-color: #80b7e230;
}

.quotation-type.aviao {
  background-color: #dee2e630;
}

.quotation-type.caminhao {
  background-color: #868ba130;
}

.config-title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}
</style>
